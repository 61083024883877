import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR, FONT_SIZE, MAX_CONTENT_WIDTH, SPACE } from 'Theme'
import { DOCUMENT_URL, EMAIL, IS_COURSE_SOLD_OUT, VIDEO_URL } from 'constants/constants'
import GenericPage from 'components/GenericPage'
import Line from 'components/Line'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { Text } from 'components/Typography'
import productManagementCoverPhoto from 'images/photos/product-management-cover.jpg'
import videoThumbnail from 'images/photos/landing-video-thumbnail.jpg'
import martinKossuthPhoto from 'images/photos/martin-kossuth-lector.png'
import pavolMadarPhoto from 'images/photos/pavol-madar-lector.png'
import janKoscelanskyPhoto from 'images/photos/jan-koscelansky-lector.png'
import SubHeroSection from 'routes/course/SubHeroSection'
import SyllabusModal, {
  useSyllabusModal,
} from 'routes/components/SyllabusModal'
import SyllabusButton from 'routes/components/SyllabusButton'
import VideoPlayer from 'components/VideoPlayer'
import Content from 'routes/components/Content'
import CTASchedule from 'components/CTASchedule'
import FAQ from 'routes/components/FAQ'
import { UnderLineLink } from 'components/UnderLineLink'
import PriceInfo from 'components/PriceInfo'
import { rem } from 'polished'
import ReviewCTA from 'components/ReviewCTA'
import TextLine from 'components/TextLine'
import LogoGrid from 'routes/components/LogoGrid'
import DescriptionList from 'routes/course/DescriptionList'
import ApplyButton, { scrollToApply } from 'routes/components/ApplyButton'
import Button from 'components/Button'
import { Link } from 'gatsby'
import ApplicationForm from './ApplicationForm'
import CoverPhoto from './CoverPhoto'
import Hero from './Hero'
import Reviews from './Reviews'
import HeroSection from './HeroSection'
import Banner from './Banner'
import DotList from './DotList'

const MENU_TITLES = {
  WHO_IS_IT_FOR: 'Komu je kurz určený',
  WHAT_IS_IT: 'Čo je to produktový manažment',
  WHAT_YOU_GET: 'Čo získaš',
  CONTENT: 'Obsah kurzu',
  INSTRUCTORS: 'Lektori',
  DESCRIPTION: 'Popis kurzu',
  APPLICATION_FORM: 'Prihlasovací formulár',
  APPLICATION_PROCESS: 'Prihlasovací proces',
  REVIEWS: 'Povedali o nás',
  FAQ: 'Často kladené otázky',
  PARTNERS: 'Začni novú kariéru',
}

const MENU_ORDER = [
  MENU_TITLES.DESCRIPTION,
  MENU_TITLES.INSTRUCTORS,
  MENU_TITLES.PARTNERS,
  MENU_TITLES.APPLICATION_FORM,
  MENU_TITLES.FAQ,
]

const OfferWrapper = styled(Flex)`
  gap: ${SPACE.L};
`

const SyllabusLink = ({ onClick }) => (
  <Text
    id="syllabus-link-faq-productmanagement"
    as="button"
    type="button"
    color={COLOR.PRIMARY_RED}
    fontSize="inherit"
    onClick={onClick}
  >
    Získaj detailný sylabus
  </Text>
)

SyllabusLink.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const LinkWithoutUnderLine = styled(Link)`
  text-decoration: none;
`

const ReviewsLink = ({ isFullWidth = false, ...rest }) => (
  <Box {...rest}>
    <LinkWithoutUnderLine to="/reviews">
      <Button variant="primary" isFullWidth={isFullWidth}>
        Pozri si recenzie študentov
      </Button>
    </LinkWithoutUnderLine>
  </Box>
)

ReviewsLink.propTypes = {
  isFullWidth: PropTypes.bool,
}

const Course = () => {
  const { isModalOpen, openModal, closeModal } = useSyllabusModal()
  const syllabusDownloadButton = (
    <SyllabusButton
      id="download-syllabus-productmanagement"
      hasDownloadIcon
      label="Získaj detailný sylabus kurzu"
      onClick={openModal}
    />
  )

  return (
    <GenericPage
      maxContentWidth={MAX_CONTENT_WIDTH.COURSE}
      onBannerClick={() => {
        scrollToApply(MENU_TITLES.APPLICATION_FORM)
      }}
    >
      <SyllabusModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        courseType="ProductManager"
        syllabusUrl={DOCUMENT_URL.SYLLABUS_PRODUCT_MANAGER}
      />
      <SEO
        title="Product Manager kurz"
        description="Nauč sa modernú metodiku riadenia digitálnych produktov a staň sa profesionálnym produktovým manažérom!"
        image="/meta/product-management.jpg"
        meta={[
          {
            name: 'title',
            content: 'Sudo Academy | Product Manager | Live Online Kurz',
          },
        ]}
      />
      <HeroSection>
        <Hero
          metaInfo={
            <>
              <span>APRÍL 2025</span>
              <Text mx={SPACE.S}>|</Text>
              <span> 8 TÝŽDŇOV </span>
              <Text mx={SPACE.S}>|</Text>
              <span> LIVE ONLINE</span>
            </>
          }
          title="Kurz Product Manager"
          subtitle="Nauč sa modernú metodiku riadenia digitálnych produktov a získaj PM certifikát"
          buttons={
            <>
              <ApplyButton
                id="apply-hero-productmanagement"
                sectionName={MENU_TITLES.APPLICATION_FORM}
              />
              <SyllabusButton
                variant="secondary"
                id="get-syllabus-hero-productmanagement"
                onClick={openModal}
              />
            </>
          }
        />
        <Flex flex="1">
          <VideoPlayer
            thumbnailUrl={videoThumbnail}
            videoUrl={VIDEO_URL.HOME}
          />
        </Flex>
      </HeroSection>
      <SubHeroSection
        leftContent={
          <>
            Product Manager je{' '}
            <Text color={COLOR.PRIMARY_RED}>top 3 pracovná pozícia</Text> na
            svete podľa rebríčka{' '}
            <UnderLineLink
              href="https://www.glassdoor.com/List/Best-Jobs-in-America-LST_KQ0,20.htm"
              target="_blank"
            >
              glassdoor
            </UnderLineLink>
          </>
        }
        rightContent={
          <>
            Ako jediní na Slovensku ti prinášame tento unikátny 8 týždňový kurz
            produktového manažmentu, ktorý ťa prevedie najmodernejšou metodikou
            budovania digitálnych produktov po vzore firiem ako{' '}
            <Text color={COLOR.PRIMARY_RED}>Netflix, Spotify, Google</Text> a
            pod.
          </>
        }
        forWhoTitle={MENU_TITLES.WHO_IS_IT_FOR}
        forWhoContent={[
          'Product Manager',
          'Product Owner',
          'Project Manager',
          'Startup Founder',
          'Analyst',
          'Software Engineer',
          'UX/UI Designer',
          'Každý koho zaujíma IT ...',
        ]}
      />

      <Line display={{ S: 'flex', M: 'none' }} mt={SPACE.XL} />

      <Content
        menuContent={MENU_ORDER}
        menuButton={
          <ApplyButton
            id="apply-menu-productmanagement"
            mt={SPACE.M}
            isFullWidth
            sectionName={MENU_TITLES.APPLICATION_FORM}
          />
        }
      >
        <Content.Section title={MENU_TITLES.DESCRIPTION}>
          <Flex
            flexDirection={{ S: 'column', M: 'row' }}
            justifyContent="space-between"
          >
            <DescriptionList
              content={[
                {
                  title: 'Forma',
                  description: 'Live interaktívna online výuka cez Zoom',
                },
                {
                  title: 'Priebeh',
                  description: '1x do týždňa v stredy večer 18:00 - 21:00',
                },
                {
                  title: 'Trvanie',
                  description: '8 týždňov - od 3. apríla do 22. mája',
                },
              ]}
            />
            <DotList
              items={[
                'Prezentácie a záznam z každej lekcie',
                'Individuálne konzultácie s lektormi',
                'Výber materiálov na domáce štúdium',
                'Možnosť pohovoru u našich partnerov',
                'Certifikát SudoAcademy Product Manager',
                'Prístup ku komunite a TOP ľuďom z oboru',
              ]}
              title="Naviac"
            />
          </Flex>
          <Grid
            display={{ S: 'grid', M: 'none' }}
            gridTemplateColumns="1fr"
            mt={SPACE.XL}
            gridGap={SPACE.M}
          >
            {syllabusDownloadButton}
            <ReviewsLink isFullWidth />
          </Grid>
          <Flex
            display={{ S: 'none', M: 'flex' }}
            border={`1px solid ${COLOR.WHITE_75}`}
            p={SPACE.L}
            mt={SPACE.XL}
            justifyContent="space-between"
            alignItems="center"
          >
            <Text as="p" fontSize={FONT_SIZE.M} lineHeight={SPACE.L}>
              Stiahni si sylabus kurzu ak hladáš detailne informácie o obsahu
              jednotlivých lekcií a presnom harmonograme
            </Text>

            <Flex ml={SPACE.XXL} flexDirection="column">
              {syllabusDownloadButton}
              <ReviewsLink mt={SPACE.M} isFullWidth />
            </Flex>
          </Flex>
        </Content.Section>
        <Content.Section title={MENU_TITLES.INSTRUCTORS}>
          <Box mb={{ S: `-${SPACE.M}`, M: 0 }}>
            <Text
              as="p"
              textAlign={{ S: 'center', M: 'left' }}
              lineHeight={SPACE.L}
              mb={{ S: rem(48), M: rem(48) }}
              pr={{ S: 0, M: SPACE.XXL }}
            >
              Naši lektori ponúkajú nie len svoje dlhoročné vedomosti a
              skúsenosti s budovaním digitálnych produktov, ale aj individuálny
              prístup a mentoring či už v kariérnej alebo startupovej oblasti.
            </Text>
            <Reviews
              items={[
                {
                  image: janKoscelanskyPhoto,
                  name: 'Ján Koscelanský',
                  position: 'CEO sudo academy',
                  text:
                    'Ján má vyše 7 ročné skúsenosti s budovaním produktov pre rôzne zahraničné technologické spoločnosti. Je držiteľom certifikátu americkej školy Product School.',
                  badge: 'Product lektor',
                  linkedIn: 'https://www.linkedin.com/in/jankoscelansky/',
                },
                {
                  image: pavolMadarPhoto,
                  name: 'Pavol Madár',
                  position: 'CTO sudolabs',
                  text:
                    'Pôsobí ako CTO v spoločnosti Sudolabs a zároveň je aj jedným z jej zakladateľov. Riadi tím 30+ developerov, ktorí vyvíjajú digitálne produkty pre úspešené US startupy.',
                  badge: 'Engineering lektor',
                  linkedIn:
                    'https://www.linkedin.com/in/pavol-madar-420b04180/',
                },
                {
                  image: martinKossuthPhoto,
                  name: 'Martin Kossuth',
                  position: 'CEO more design',
                  text:
                    'Martin má vyše 7 ročné skúsenosti v digitálnom dizajne a budovaní značiek pre inovátorov a startupy. Vedie košické UX & branding štúdio More Design.',
                  badge: 'Design lektor',
                  linkedIn:
                    'https://www.linkedin.com/in/martin-kossuth-500090a0/',
                },
              ]}
              cols={3}
            />
          </Box>
        </Content.Section>
        <Content.Section title={MENU_TITLES.PARTNERS}>
          <Text
            as="p"
            textAlign={{ S: 'center', M: 'left' }}
            lineHeight={SPACE.L}
            mb={{ S: rem(48), M: rem(32) }}
            pr={{ S: 0, M: SPACE.XXL }}
          >
            Náš kurz nie je len o samotnom vzdelávaní, ale úspešným absolventom
            taktiež pomôžeme získať prácu u našich partnerských spoločností
          </Text>
          <LogoGrid />
          <Box mt={SPACE.XL}>
            <Line />
          </Box>
        </Content.Section>
        <ReviewCTA
          buttonTitle="Pozri si recenzie študentov"
          linkTo="/reviews"
        />
        <Content.Section
          title={MENU_TITLES.APPLICATION_FORM}
          hasTopBorder={false}
        >
          <OfferWrapper
            flexDirection={{ S: 'column', M: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Grid
              flexDirection="column"
              gridGap={{ S: SPACE.M, M: SPACE.L }}
              width={`min(100%, ${rem(400)})`}
              mb={{ S: SPACE.L, M: 0 }}
            >
              <PriceInfo
                price="990€"
                description="Ideálna možnosť ak chceš zaplatiť celú sumu teraz a neodkladať to na neskôr."
                title="Plná cena"
              />
              <TextLine text="alebo" />
              <PriceInfo
                price="3 x 330€"
                description="Ak preferuješ rozdeliť platbu na 3 splátky, radi ti to umožníme."
                title="Splátky"
              />
            </Grid>
            <ApplicationForm
              coursePrice={990}
              courseType="ProductManager"
              isCourseSoldOut={IS_COURSE_SOLD_OUT}
            />
          </OfferWrapper>
        </Content.Section>
        <Banner
          title="Stále si sa nerozhodol?"
          subtitle="Pozri si detailný obsah kurzu a recenzie našich absolventov"
          linkText="Recenzie študentov"
        />
        <Content.Section title={MENU_TITLES.FAQ}>
          <FAQ
            items={[
              {
                question:
                  'Čo je úlohou Product Managera a je o túto rolu v IT firmách záujem?',
                answer: (
                  <>
                    Každý digitálny produkt potrebuje okrem programátorov a
                    dizajnérov aj produktového manažéra, ktorého
                    úlohou je detailne pochopiť potreby zákazníka a na ich
                    základe stanoviť produktovú víziu a stratégiu, koordinovať
                    následný vývoj a zodpovedať za biznisové výsledky produktu.
                    Aktuálne ide o jednu z najvyhľadávanejších pozícií v
                    technologických firmách. Prečítaj si náš detailný článok o
                    produktovom managemente{' '}
                    <UnderLineLink
                      href="/blog/preco-sa-stat-produktovym-manazerom"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      TU
                    </UnderLineLink>
                  </>
                ),
              },
              {
                question:
                  'Koľko hodín týždenne si potrebujem vyhradiť na tento kurz?',
                answer:
                  'Tento kurz je koncipovaný part-time formou, to znamená, že je možné ho bez problémov zvládnuť aj popri práci alebo štúdiu. Spoločná výuka bude prebiehať 1x do týždňa vo večerných hodinách od 18:00 do 21:00. Počas kurzu sa naviac ešte 2x uskutočnia spoločné pracovné worskopy (2 štvrtky večer od 18:00 do 20:00). Zvyšný čas behom týždňa je venovaný na vypracovanie domácich zadaní, samoštúdium a konzultácie s lektorom (max. 1 hodina do týždňa).',
              },
              {
                question:
                  'Získam automaticky po absolvovaní kurzu pracovnú ponuku?',
                answer:
                  'Náš kurz je zostavený tak, aby si po jeho absolvovani bol(a) pripravený(á) nastúpiť do technologickej firmy na pozíciu Product Manager / Product Owner. Môžeme ti pomôcť stretnúť sa s našimi IT partnermi a v prípade obojstranného záujmu ti sprostredkujeme u ktoréhokoľvek z nich pracovný pohovor. To, či tento pohovor skončí úspešne je už samozrejme na tebe, ale môžeme ti garantovať, že po vedomostnej stránke budeš ovládať všetko čo je potrebné na získanie práce produktového manažéra.',
              },
              {
                question:
                  'Aktuálne nemám žiadne skúsenosti s IT, môžem sa aj tak prihlásiť na tento kurz?',
                answer:
                  'Ak ťa zaujímajú technológie a máš nápad na digitálny produkt, ktorý by si rád validoval a naučil sa ako pristupovať k jeho realizácii, tak potom bude tento kurz pre teba určite vhodný. Zašli nám svoju online prihlášku, ktorá je nezáväzná a na vstupnom online pohovore, si spolu prejdeme všetky tvoje prípadné otázky a potvrdíme vhodnosť tvojho zapojenia.',
              },
              {
                question:
                  'Zaujímam sa o pozíciu “Product owner”, bude pre mňa tento kurz prínosný?',
                answer:
                  'Určite áno! Product owner je pôvodne názov role v rámci metodológie SCRUM a z veľkej väčšiny sa kompetencie Product Ownera a Product Manažéra prekrývajú (v mnohých firmách ide iba o rozdielnu terminológiu). Tento kurz plnohodnotne pokrýva všetky aspekty práce Product Ownera (od prioritizácie roadmapy, cez jej delivery až po výslednú analytiku) a pridáva naviac aj strategický pohľad prvotnú definíciu toho, akú hodnotu má produkt prinášať pre užívateľa.',
              },
            ]}
          />
          <CTASchedule
            description="Ak máš akékoľvek otázky ohľadom nášho kurzu, naplánuj si s nami nezáväznú konzultáciu a v krátkom video telefonáte si spolu prejdeme všetko čo ťa zaujíma."
            buttonText="Vyber si termín konzultácie"
            subButtonText="alebo nám napíš na "
            subButtonEmail={EMAIL.SUDO_ACADEMY}
          />
        </Content.Section>
      </Content>
      <CoverPhoto src={productManagementCoverPhoto} />
    </GenericPage>
  )
}

export default Course
